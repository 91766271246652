.default-button {
    border: none;
    color: white;
}

.onmenu-button {
    margin: 10px auto;
    border: none;
    color: white;
    width: 200px;
    height: 42px;
    background: linear-gradient(95.95deg, #4285F4 1.17%, #4285F4cc);
    box-shadow: 0px 4px 15px rgba(62, 124, 227, 0.25);
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.03em;
    cursor: pointer;
}